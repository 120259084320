import React from "react";
import Layout from "../../components/UI/layout";
import Header from "../../components/UI/Header/header";
import SEO from "../../components/UI/seo";
import Help from "../../components/challenges/challengeThree/Help/help";
import ImplementationSection from "../../components/challenges/challengeThree/implementations/implementations";
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next";



const ChallengeThree = () => {
  const {t} = useTranslation();
  return(
    <Layout>
      <SEO title="Codeicus | ChallengesThree" />
      <Header
        title="Tener mayor control de tus implementaciones"
        description={["Cuando la tecnología avanza en el negocio, los proyectos y proveedores de desarrollo también. <br></br>Tener control sobre las implementaciones es clave para mantener la aplicación core del negocio sana y funcionando a toda máquina. <br></br>Desde Codeicus implementamos para nuestros clientes financieros integración continua y aumentamos el control de las interacciones en 100%."]}
      />
      <ImplementationSection/>
      <Help t={t}/>
    </Layout>
  )
}
  
  export default ChallengeThree

  export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;