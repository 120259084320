// extracted by mini-css-extract-plugin
export var challengeSection = "implementations-module--challengeSection--0706c";
export var containBody = "implementations-module--containBody--ba988";
export var containCars = "implementations-module--containCars--e2ba3";
export var containProject = "implementations-module--containProject--54cf2";
export var containTitle = "implementations-module--containTitle--9935a";
export var description = "implementations-module--description--65e59";
export var detalle = "implementations-module--detalle--ae7b1";
export var number = "implementations-module--number--d23dd";
export var step = "implementations-module--step--04ed2";
export var title = "implementations-module--title--62e70";