import React from 'react';
import * as styles from './implementations.module.scss';
import { useTranslation } from "gatsby-plugin-react-i18next"



const ImplementationSection = () => {


    const {t} = useTranslation();

	return (
		<section className={styles.challengeSection} id='challengeSection'>
			<div className={styles.containProject}>
                <div className={styles.containTitle}    
                    data-sal-delay='400'
                    data-sal='slide-up'
                    data-sal-duration='400'
                    data-sal-easing='ease-out-back'>
                    <h3>{"Contar con implementación continua les permitió "}</h3>
                    
                </div>
                <div className={styles.containBody}>
                    <div className={styles.containBody}>                   
                    <div className={styles.step}
                         data-sal-delay='400'
                        data-sal='slide-right'
                        data-sal-duration='500'
                        data-sal-easing='ease-out-back'>
                        <div className={styles.detalle}>
                            <div className={styles.number}>
                                <p>1</p>
                            </div>
                            <div className={styles.title}>     
                                <h3>{"Reducción en costo de proyectos"}</h3> 
                            </div>
                        </div>
                    </div>
                    <div className={styles.step}                        
                        data-sal-delay='400'
                        data-sal='slide-right'
                        data-sal-duration='500'
                        data-sal-easing='ease-out-back'>
                        <div className={styles.detalle}>
                            <div className={styles.number}>
                                <p>2</p>
                            </div>
                            <div className={styles.title}>     
                                <h3>{"Trazabilidad de acciones de diversos proveedores "}</h3> 
                            </div>
                        </div>
                    </div>
                    <div className={styles.step}                        
                        data-sal-delay='400'
                        data-sal='slide-right'
                        data-sal-duration='500'
                        data-sal-easing='ease-out-back'>
                        <div className={styles.detalle}>
                            <div className={styles.number}>
                                <p>3</p>
                            </div>
                            <div className={styles.title}>     
                                <h3>{"Disminución de impacto de rollbacks y correcciones "}</h3> 
                            </div>
                        </div>
                    </div> 
                    <div className={styles.containTitle}
                       data-sal-delay='400'
                       data-sal='slide-up'
                       data-sal-duration='400'
                       data-sal-easing='ease-out-back'>
                        <p>{"Ahora la empresa tiene el control y puede organizar el trabajo en conjunto de varios proveedores sin riesgo para el negocio."}</p>
                    </div>
                </div>
                </div>

			</div>
		</section>
	);
};
export default ImplementationSection ;