// extracted by mini-css-extract-plugin
export var bodyHelp = "help-module--bodyHelp--8fb5c";
export var btn = "help-module--btn--bc3da";
export var btnLottie = "help-module--btnLottie--df1c9";
export var contenerHelp = "help-module--contenerHelp--e587a";
export var detail = "help-module--detail--d91b6";
export var error = "help-module--error--31dc1";
export var fromCenter = "help-module--fromCenter--e4582";
export var fromContacto = "help-module--fromContacto--a3460";
export var fromLeft = "help-module--fromLeft--9d5f6";
export var textArea = "help-module--textArea--83787";
export var textBox = "help-module--textBox--5a822";
export var title = "help-module--title--7ab25";